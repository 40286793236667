import './App.css';
var ReactGA = require('react-ga');

const btns = [
  {
    id: 1,
    image: 'images/keybase.svg',
    altText: 'keybase',
    eventLabel: 'Keybase',
    href: 'https://keybase.io/charlessullivan',
  },
  {
    id: 2,
    image: 'images/aws.svg',
    altText: 'aws',
    eventLabel: 'AWS',
    href: 'https://www.youracclaim.com/users/charles.dev/badges',
  },
  {
    id: 3,
    image: 'images/github.svg',
    altText: 'github',
    eventLabel: 'GitHub',
    href: 'https://github.com/CharlieSu',
  },
  {
    id: 4,
    image: 'images/linkedin.svg',
    altText: 'linkedin',
    eventLabel: 'LinkedIn',
    href: 'https://www.linkedin.com/in/charlessullivan1/',
  },
];

function ButtonList() {
  return (
    <div className="text-center py-2 space-x-1">
      {btns.map((btn, btnIdx) => (
        <ReactGA.OutboundLink
          eventLabel={btn.altText}
          to={btn.href}
          target="_blank"
          key={btn.id}
        >
          <button className="h-8 w-8  rounded inline-flex items-center">
            <img src={btn.image} alt={btn.altText} />
          </button>
        </ReactGA.OutboundLink>
      ))}
    </div>
  );
}

function App() {
  return (
    <div id="parent">
      <div className="max-w-sm mx-auto content-center block bg-white rounded-lg p-10 shadow-xl ">
        <img
          className="h-24 w-24 md:h-24 md:w-24 rounded-full mx-auto"
          src="images/charles.jpg"
          alt="avatar"
        />

        <div className="text-center md:text-left">
          <div className="divide-y divide-black-50">
            <div className="text-center py-2">
              <h2 className="text-lg">Charles Sullivan</h2>
              <div className="text-blue-500">
                <ReactGA.OutboundLink
                  eventLabel="AWS"
                  to="https://www.youracclaim.com/users/charles.dev/badges"
                  target="_blank"
                >
                  Ops Focused Engineer
                </ReactGA.OutboundLink>
              </div>
              <div className="text-gray-600">contact@charles.dev</div>
              <div className="text-gray-600">
                <a href="tel:+1-720-397-1492">(720) 397-1492</a>
              </div>
            </div>

            <div>{ButtonList()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
